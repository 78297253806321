:root {
  --theme-black: #1a181b;
  --theme-gray: #9e9e9e;
  --theme-green: #479761;
  --theme-crocke: #cebc81;
  --theme-salmon: #a16e83;
  --theme-pink: #b19f9e;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: Poppins, Roboto, Arial, "Courier New", Courier, monospace;
}

.under-construction {
  /* background-color: #c1daf3; */
  text-align: center;
  margin-top: 40px;
}
.big-logo {
  width: 50vw;
  margin: 10px;
}
.App-logo {
  width: 120px;
  /* background-image: url('/imgs/logo-white.png');
  width: 100%; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
section {
  height: 100%;
}
.section-title {
  margin: 20px !important;
}

.service-title {
  margin: 20px !important;
  font-weight: 500 !important;
  color: var(--theme-crocke);
}

nav {
  margin: 20px;
  color: black !important;
}
.navbar {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  color: black !important;
  background-color: transparent;
  z-index: 10;
  padding: 30px 0px 0px 0px;
}
.active-nav {
  height: 60px;
  background-color: white;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  padding: 10px 0px 0px 0px;
}

.app-logo-scroll {
  display: none;
}
.active-nav .app-logo-scroll {
  width: 120px;
  display: block;
}
.active-nav .App-logo {
  display: none;
}

.active-nav .menu-item,
.active-nav .menu-action {
  color: var(--theme-green) !important;
  border-color: var(--theme-green);
}

.header_topnav_right {
  font-size: 1.4rem;
  float: right;
  margin: 15px 0px;
}
.header_topnav_left {
  float: left;
  margin: 0px;
}
.menu-item {
  margin-right: 20px !important;
  color: white !important;
  text-transform: capitalize;
  font-weight: bold;
  text-decoration: none !important;
}

.menu-action {
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px;
  color: white !important;
  font-weight: bold;
  text-decoration: none !important;
}
.headline {
  margin-bottom: 20px !important;
}
#hero {
  width: 100%;
  height: 100vh;
  /* clip-path: polygon(45% 0, 45% 0, 90% 100%, 0% 100%); */
}

.video-container {
  background-color: var(--theme-black);
}

#header-video {
  opacity: 0.8;
  height: 100%;
}

.headline-container {
  position: absolute;
  top: 50%;
  width: 45%;
  text-align: left;
  color: white;
}

.header-button {
  margin-top: 20px !important;
  padding: 10px 30px!important;
  font-size: 1.5rem !important;
  background-color: var(--theme-green) !important;
  text-transform: capitalize !important;
}

.service-button {
  margin-top: 20px !important;
  padding: 10px 30px!important;
  font-size: 1.5rem !important;
  background-color: var(--theme-green) !important;
  text-transform: capitalize !important;
}


#services {
  padding: 40px 0px;
}
.service-button{
  margin-top: 20px !important;
  padding: 10px 30px!important;
  font-size: 1.5rem !important;
  background-color: var(--theme-green) !important;
  text-transform: capitalize !important;
}
#articles {
  padding: 60px 0px;
}
.services-container {
}
/* .services-container div {
  padding: 20px 5px;
  margin: auto;
  color: var(--theme-black);
    text-align: center;
} */

.client-logo {
  height: 60px;
  width: fit-content;
  padding: 10px;
}

#quorin{
  width: fit-content;
  height: 30px;
  padding: 15px;
}

.overlay-mask {
  background-color: var(--theme-black);
  opacity: 0.55;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
}

.paper {
  padding: 20px 0px;
  margin-top: 10px;
  text-align: left !important;
  box-shadow: none !important;
}
.paper-title {
  margin-bottom: 20px !important;
  color: var(--theme-green);
}
.paper-title:hover {
  color: var(--theme-crocke);
}
.paper-subtitle {
  color: var(--theme-black);
}

#values {
  position: relative;
  text-align: center;
  padding: 60px;
  color: white;
}
.value-img {
  background-image: url("/public/imgs/values_cover.jpg");
  background-size: cover;
  background-position-y: -80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.values-container {
  z-index: 2;
  position: relative;
}
.value-tabs {
  text-align: center;
  margin: auto !important;
  position: relative;
}
.value-tabs .MuiTab-root{
  color: var(--theme-gray);
}
.value-tabs .Mui-selected{
  color: white !important;
}

.value-title {
  z-index: 2;
  position: relative;
  margin: 20px !important;
  color: var(--theme-crocke);
}

.value-text {
  width: 40vw;
  margin: auto !important;
  position: relative;
}

.MuiTabs-indicator {
  background-color: var(--theme-green) !important;
}

#contact {
  font-size: 1.4rem;
  /* padding: 20px 0; */
  text-align: center !important;
  text-align: -webkit-center;
}

.contact-container{
  /* margin-top: 40px; */
}

.contact-label,
.contact-input {
  font-size: 2.4rem !important;
  margin: auto;
  color: var(--theme-black) !important;
}
.MuiInput-underline::after {
  border-bottom: 2px solid var(--theme-green) !important;
}
.contact-helper-text {
  font-size: 1.4rem !important;
}

.MuiFormControl-root {
  margin: 25px !important;
}

.form-button {
  background-color: var(--theme-green) !important;
  color: white !important;
  padding: 10px !important;
  font-size: 2rem !important;
  width: inherit;
  align-self: center;
}
.form-button:disabled {
  background-color: var(--theme-gray) !important;
  color: white !important;
}


.privacy-policy-check {
  font-size: 2rem !important;
  margin: auto;
  color: var(--theme-black) !important;
}
.privacy-policy-check a {
  color: var(--theme-black) !important;
}
.Mui-checked {
  color: var(--theme-green) !important;
}
.form-error{
  color: red !important;
}
footer {
  background-color: var(--theme-black);
  color: var(--theme-gray);
  position: relative;
  bottom: 0;
  font-size: 1.4rem;
  text-align: center;
}
span {
  display: grid;
  margin: 10px;
}
.marginTop25 {
  margin-top: 25px;
}
.footer-logo {
  color: white;
  width: 100px;
  margin: auto;
}
.footer-item {
  margin: 20px 5px;
  padding-top: 20px;
  text-align: left;
  max-width: 160px;
}

.footer-talk-item h5{
  position: absolute;
  width: 260px;
}

.footer-talk-item a{
  position: relative;
  top: 80px;
  width: 250px;
}

.footer-item span {
  margin-left: 0px;
}

.footer-menu-item{
  margin-right: 20px !important;
  color: white !important;
  text-transform: capitalize;
  font-weight: bold;
  text-decoration: none !important;
  margin-top: 10px;
}
.footer-menu-item:hover{
  color: var(--theme-crocke) !important;
}

.trademark {
  padding: 20px;
}
.privacy-policy {
  padding-bottom: 20px;
}

.clients-container{
  padding: 40px 0px;
}

.case-studies-img {
  background-image: url("/public/imgs/values_cover.jpg");
  background-size: cover;
  background-position-y: -80px;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  height: 40vh;
  position: relative;
}
#case-studies{
  text-align: center;
  /* height: 100vh; */
}
.case-studies-main-section{
  /* text-align: center; */
  max-width: 1200px;
  margin: 60px auto; 
  padding: 0px 24px !important;
}

.page-header{
  z-index: 10;
  color: white;
  position: absolute;
  bottom: 40px;
  /* margin-left: 60px !important; */
}

.project-card ::before :hover{
  background-color: var(--theme-crocke);
  content: 'asda';
}

.project-img-container{
  overflow-y: scroll;
  margin: auto;
  height: 400px;
}
.project-desc{ 
  text-align: justify;
  color: var(--theme-gray);
  font-size: 1.5rem !important;
}
.project-img{
  width: -webkit-fill-available;
}

.projects-button{

  margin-top: 20px !important;
  padding: 10px 30px!important;
  font-size: 1.5rem !important;
  background-color: var(--theme-green) !important;
  text-transform: capitalize !important;
}

/* Desktop end */

/* mobile */
@media (min-width: 320px) and (max-width: 480px) {
  .burger-icon{
    color: white;
    font-size: 3rem;
  }
  .app-logo-mobile{
    position: relative;
    width: 120px;
    margin-left: 5vw;
    top: 25px;
  }

  .menu-close{
    margin: 25px 8px !important;
    color: black !important; 
  }

  .menu-close svg{
    color: #000;
  }
  .mobile-menu-li span ,svg {
    font-size: 2.5rem !important;
    color: #000;
    margin: 0px; 
    min-width: none; 
  }

  .headline-container{
    text-align: center;
  }

  .project-card{
   width: 90vw; 
   margin: auto;
  }

  .service-button {
    /* margin-left: 30px !important; */
  }
  
  .footer-grid{
    justify-content: space-evenly !important;
    padding-bottom: 40px;
  }

  .footer-item {
    max-width: 90vw !important;
    text-align: center;
    justify-content: center;
    max-width: fit-content;
  }

  .footer-item h6{
    font-weight: bold;
  }

  .footer-talk-item h5{
    position: relative;
    margin: 20px;
    width: fit-content;
  }

  .footer-talk-item  a{
    position: relative;
    margin: 20px;
    top: unset;
  }
  .form-control , .MuiInputBase-input{
    width: 80vw !important;
  }
  .MuiFormControl-root{
    width: 80vw !important;
    margin: 25px 0px !important;
  }
  .case-studies-img{
    background-position-y: unset;
  }
}

@media (min-width: 1340px) and (max-width: 1600px) {
  .headline-container {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    top: 30%;
  }
}

@media (min-width: 1090px) and (max-width: 1339px) {
  .headline-container {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    top: 30%;
  }
  .MuiTypography-h1 {
    font-size: 5rem !important;
  }

  .MuiTypography-h4 {
    /* font-size: 5rem; */
  }
  .MuiTypography-h5 {
    /* font-size: 5rem; */
  }
}

@media (min-width: 960px) and (max-width: 1089px) {
  .headline-container {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    top: 25%;
    width: 70%;
  }
  .MuiTypography-h1 {
    font-size: 5rem !important;
  }

  .MuiTypography-h4 {
    /* font-size: 5rem; */
  }
  .MuiTypography-h5 {
    /* font-size: 5rem; */
  }
}

@media (min-width: 769px) and (max-width: 960px) {
  .headline-container {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    top: 45%;
    width: 90%;
  }
  .MuiTypography-h1 {
    font-size: 3rem !important;
    font-weight: 500 !important;
  }

  .MuiTypography-h3 {
    font-size: 2.2rem !important;
    /* font-weight: 300 !important; */
  }
  .MuiTypography-h4 {
    font-size: 2rem !important;
    font-weight: 300 !important;
  }

  /* .video-container{
    height: 100vh;
  } */
}

@media (min-width: 481px) and (max-width: 768px) {
  .headline-container {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    top: 45%;
    width: 90%;
  }
  .MuiTypography-h1 {
    font-size: 5rem !important;
    font-weight: 500 !important;
  }

  .MuiTypography-h3 {
    font-size: 3rem !important;
    /* font-weight: 300 !important; */
  }
  .MuiTypography-h4 {
    font-size: 2.4rem !important;
    font-weight: 300 !important;
  }
  .header_topnav_right,
  footer {
    font-size: 1.8rem;
  }
}

@media (min-width: 330px) and (max-width: 480px) {
  .headline-container {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    top: 45%;
    width: 90%;
  }
  .MuiTypography-h1 {
    font-size: 3rem !important;
    font-weight: 500 !important;
  }

  .MuiTypography-h3 {
    font-size: 2.2rem !important;
    /* font-weight: 300 !important; */
  }
  .MuiTypography-h4 {
    font-size: 2rem !important;
    font-weight: 300 !important;
  }

  .video-container {
    height: 100vh;
  }
  .value-img {
    background-position-y: unset;
  }
}
